import { renderFile } from 'ejs';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);



const messages = {
    'en': {
    	login: { 
			logIn: 'LOGIN',
			signUp: 'SIGN UP',
			mobileNumber: 'Mobile Number',
			emailAddress: 'Email Address',
			accountNumber: 'Account Number',

			pinLabel: '6-Digit PIN',
			showToggle: 'Show',
			hideToggle: 'Hide',

			forgotPIN: 'Forgot PIN?',
			forgotPINLabel: 'FORGOT PIN',
			submit: 'SUBMIT',

			backToLogin: 'Back to Login',

			errors: {
				noMobile: 'Please provide a valid mobile no.',
				mobileHasZero: 'Please remove 0 in front of your mobile number',
				noEmail: 'Please provide a valid email address',
				noAccountNum: 'Please provide a valid account number',
				noPin: 'Please provide a valid PIN',
				pinInvalidDigits: 'PIN must by 6 digits',
			},

			tempPinSent: 'Temporary PIN has been sent'
		},

		signup: {
			logIn: 'LOGIN',
			signUp: 'SIGN UP',
			mobileNumber: 'Mobile Number',
			emailAddress: 'Email Address',
			accountNumber: 'Account Number',

			pinLabel: 'Enter your 6-Digit PIN for Security',
			showToggle: 'Show',
			hideToggle: 'Hide',

			authentication: 'AUTHENTICATION',
			sentAuth: 'Authentication code has been sent to your mobile/email. Kindly enter below',
			no2FAMsg1: "Didn't receive 2FA?",
			no2FAMsg2: 'Resend Now',
			cancel: 'CANCEL',

			errors: {
				noMobile: 'Please provide a valid mobile no.',
				mobileHasZero: 'Please remove 0 in front of your mobile number',
				noEmail: 'Please provide a valid email address',
				noAccountNum: 'Please provide a valid account number',
				noPin: 'Please provide a valid PIN',
				pinInvalidDigits: 'PIN must by 6 digits',
			}
		},

		setupProfile: {
			changePin: 'CHANGE PIN',
			setupName: 'SETUP NAME',

			firstName: 'First Name',
			lastName: 'Last Name',
			submit: 'Submit',

			currentPin: 'Current PIN',
			newPin: 'New PIN',
			confirmPin: 'Confirm PIN',
		},

		setupWallet: {
			selectWallet: 'SELECT WALLET',
			pleaseConnectNetwork: 'Please connect to BLXQ Network using Metamask',
			generateAddress: 'Generate Wallet Address'
		},

		home: {
			vestingSchedule: "Next Vesting schedule",
			chooseLang: 'Choose Language',
			sidebar: {
				dashboard: 'Dashboard',
				profile: 'Profile',
				transactionHistory: 'Transaction History',
				changePin: 'Change PIN',
				support: 'Support',
				signOut: 'Sign Out'				
			},

			lockedTokens: 'My HOMS',
			wallet: 'My Wallet',
			secondaryWallet: 'Secondary Wallet',
			walletBalance: 'Wallet Balance',
			lockedWalletBalance: 'Locked Tokens',
			unlockedWalletBalance: 'Unlocked Tokens',
			send: 'Send',
			receive: 'Receive',
			buy: 'Buy',
			
			recentTransactions: 'Recent Transactions',
			noTransactions: 'No transactions found',
			recentTransfers: 'Recent Transfers',
			noTransfers: 'No transfers found',
			viewAll: 'View All',

			connectMetamask: 'CONNECT METAMASK',
			pleaseLogin: 'Please login to your Metamask and/or connect to the BLXQ Network',
			reload: 'Reload',

			timestamp: 'Timestamp',
			transactionHash: 'Transaction Hash',
			input: 'Input',
			output: 'Output',
			amount: 'Amount',
			homsTokens: 'HOMS Tokens',
			totalPurchase: 'Total Purchase',
			totalClaim: 'Total Claim',
			upfrontRelease: 'Upfront Release',
			vestingCycle: 'Vesting Cycle',
			nextVesting: 'Next Vesting Schedule',
			mainWallet: 'Main Wallet',
			bonusWallet: 'Bonus Wallet',
		},
		
		modal: {
			send: {
				testTxnNote: 'Note: Please send 1 HOMS as a test transaction to make sure you enter the correct recipient/wallet address.',
				sendHoms: 'SEND HOMS',
				recipientAddress: 'Recipient Address',
				amount: 'Amount',
				send: 'Send',
				balance: 'Balance',

				enterYourPin: 'Enter your PIN',
				submit: 'SUBMIT',
				enterOneTimePin: 'Enter the one-time PIN you received',
				no2FAMsg1: "Didn't receive 2FA?",
				no2FAMsg2: 'Try Again',
				done: 'DONE',
				
				blxqBalance: "Your available BLXQ balance is: ",
				solanaBlxqBalance: "You are about to send HOMS from BLXQ network to SOLANA network. ",

				errors: {
					noRecipientAddress: 'Recipient wallet address is required',
					noSendToOwn: "You can't send to your own wallet address",
					amountRequired: 'Amount is required',
					invalidPrivateKey: 'Invalid Private Key',
					insufficientBalance: 'Insufficient balance',
					isRequired: 'This is required',
					invalidWalletAddress: 'Invalid Wallet Address',
					insufficientGasFee: 'Insufficient BLXQ Funds for Gas Fee',
					insufficientAvailableBonus: 'You have insufficient available balance. Some of your tokens are still locked',
				},

				transactionSent: 'Transaction sent to the blockchain. Please wait.',
				transactionRequestSent: 'Transaction sent. Please wait.',
				transactionCancelled: 'You cancelled the transaction in Metamask. Please try again.',
				errorSending: 'Error in sending token',
			},

			deposit: {
				depositHoms: 'DEPOSIT HOMS',
				balance: 'Balance',
				scanQr: 'Scan the QR Code or Send the coins to this HOMS address',
				copy: 'COPY',
				copied: 'Copied!'
			},

			buy: {
				buyHoms: 'BUY HOMS',
				selectNetwork: 'Select Network',
				network: 'Network',
				scanQr: 'Scan the QR code or send the coins to this HOMS address',
				copy: 'Copy',
				copied: 'Copied!',
				payUsdt: 'You will pay in USDT',
				payHoms: 'You will pay in HOMS',
				transactionHash: 'Transaction Hash',
				send: 'Send',

				requestSent: 'Your request has been successfully submitted and is now being processed.',
				done: 'Done',

				errors: {
					isRequired: 'This is required',
					noUSDTAmount: 'Please enter USDT amount',
					invalidHash: 'Invalid Hash',
				}
			},

			confirmLogout: {
				areYouSure: 'ARE YOU SURE?',
				ok: 'OK',
				cancel: 'CANCEL'
			}
		},

		profile: {
			updateProfile: 'Update Profile',
			firstName: 'First Name',
			lastName: 'Last Name',
			update: 'Update',
			updateEmail: 'Update Email Address',
			updateMobile: 'Update Mobile Number',
			save: 'Save',
			edit: 'Edit',
			cancel: 'Cancel',
			nameUpdated: 'Name successfully updated',
			emailUpdated: 'Email successfully updated',
			mobileUpdated: 'Mobile Phone successfully updated',
		},

		history: {
			recentTransactions: 'Recent Transactions',
			timestamp: 'Timestamp',
			amount: 'Amount',
			inboundAddress: 'Inbound Address',
			outboundAddress: 'Outbound Address',
			txnId: 'TXN ID',
			status: 'STATUS',
			input: 'INPUT',
			output: 'OUTPUT',
			recentTransfers: 'Recent Transfers',
			hash: 'HASH'
		},

		changePin: {
			changePin: 'Change PIN',
			currentPin: 'Current PIN',
			newPin: 'New PIN',
			confirmNewPin: 'Confirm New PIN',
			confirm: 'Confirm',
			changedPin: 'Successfully changed PIN',

			errors: {
				noMatchingPins: 'Entered new PINs do not match',
				noCurrentPin: 'Invalid current PIN',
				currentPinDigits: 'Current PIN must be 6 digits',
				newPinDigits: 'New PIN must be 6 digits',
			}
		}
    },
	//
	//
	//
	//
	// 
	//
	//
	//
    'kr': { 
		login: {
			logIn: '로그 인',
			signUp: '회원가입',
			mobileNumber: '모바일 번호',
			emailAddress: '이메일주소',
			accountNumber: '회원번호',

			pinLabel: '6자리 PIN',
			showToggle: '보임',
			hideToggle: '숨김',

			forgotPIN: 'PIN을 잊어버렸다고요?',
			forgotPINLabel: 'PIN을 잊어버렸어요',
			submit: '제출해요',

			backToLogin: '다시 로그인 페이지로 이동',

			errors: {
				noMobile: '올바른 휴대 전화 번호를 제공하십시오',
				mobileHasZero: '휴대전화 번호 앞에 0을 제거하십시오',
				noEmail: '올바른 이메일 주소를 제공하십시오',
				noAccountNum: '올바른 계정 번호를 제공하십시오',
				noPin: '올바른 PIN을 제공하십시오',
				pinInvalidDigits: 'PIN은 6자리여야 해요',
			},

			tempPinSent: '임시 PIN이 전송되었습니다'
		},

		signup: {
			logIn: '로그 인',
			signUp: '회원가입',
			mobileNumber: '모바일 번호',
			emailAddress: '이메일주소',
			accountNumber: '계좌번호',

			pinLabel: '보안을 위해 6자리 PIN을 입력하십시오',
			showToggle: '보임',
			hideToggle: '숨김',

			authentication: '인증',
			sentAuth: '고객님의 모바일/이메일로 인증코드가 발송되었습니다. 아래를 입력해주세요',
			no2FAMsg1: '2FA 안 받으셨어요?',
			no2FAMsg2: '지금 다시 보내기',
			cancel: '취소해요',

			errors: {
				noMobile: '올바른 휴대 전화 번호를 제공하십시오',
				mobileHasZero: '휴대전화 번호 앞에 0을 제거하십시오',
				noEmail: '올바른 이메일 주소를 제공하십시오',
				noAccountNum: '올바른 계정 번호를 제공하십시오',
				noPin: '올바른 PIN을 제공하십시오',
				pinInvalidDigits: 'PIN은 6자리여야 해요',
			}
		},

		setupProfile: {
			changePin: 'PIN 바꿔요',
			setupName: '이름을 세워요',

			firstName: '이름',
			lastName: '성',
			submit: '제출해요',
			
			currentPin: '현재 PIN',
			newPin: '새로운 PIN',
			confirmPin: '새 PIN 확인',
		},

		setupWallet: {
			selectWallet: '지갑을 선택해요',
			pleaseConnectNetwork: '메타마스크를 사용하여 BLXQ 네트워크에 연결하십시오',
			generateAddress: '지갑 주소를 생성해요'
		},

		home: {
			vestingSchedule: "다음 베스팅 일정",
			chooseLang: '원하는 언어 선택',
			sidebar: {
				dashboard: '홈',
				profile: '프로필',
				transactionHistory: '거래 내역',
				changePin: '비밀번호 변경',
				support: '고객지원',
				signOut: '로그아웃'
			},

			lockedTokens: '잠긴 토큰',
			wallet: '지갑',
			secondaryWallet: '지갑',
			walletBalance: '지갑 잔고',
			lockedWalletBalance: 'Locked Tokens',
			unlockedWalletBalance: 'Unlocked Tokens',
			send: '보내기',
			receive: '받기',
			buy: '구매',
			
			recentTransactions: '구매 내역',
			noTransactions: '구매 내역을 찾을 수 없음',
			recentTransfers: '자산 이동 내역',
			noTransfers: '이동 내역을 찾을 수 없음',
			viewAll: '모두 보기',

			connectMetamask: '메타마스크를 연결해요',
			pleaseLogin: '메타마스크에 로그인하거나 BLXQ 네트워크에 연결하십시오',
			reload: '재장전해요',

			timestamp: '시각',
			transactionHash: '거래 해시',
			input: '입금',
			output: '출금',
			amount: '수량',
			homsTokens: 'HOMS 토큰',
			totalPurchase: '총 구매 수량',
			totalClaim: '총 판매 가능수량',
			upfrontRelease: '다음 락 해제 수량',
			vestingCycle: '총 락해제 남은 시간',
			nextVesting: '이번 락 해제 남은 시간',
			mainWallet: '메인 지갑',
			bonusWallet: '보너스 지갑',
		},
		
		modal: {
			send: {
				testTxnNote: '참고: 올바른 수취인/지갑 주소를 입력했는지 확인하기 위해 테스트 거래로 HOMS 1개를 보내주십시오.',
				sendHoms: 'HOMS 보내기',
				recipientAddress: '수취인 주소',
				amount: '수량',
				send: '보내기',
				balance: '지갑 잔고',

				enterYourPin: 'PIN을 입력하십시오',
				submit: '제출해요',
				enterOneTimePin: '받은 일회용 PIN을 입력합니다',
				no2FAMsg1: "2FA 안 받으셨어요?",
				no2FAMsg2: '재시도',
				done: '알았어',
				
				blxqBalance: "사용 가능한 BLXQ 잔액은 다음과 같습니다. ",
				solanaBlxqBalance: "BLXQ 네트워크에서 SOLANA 네트워크로 HOMS를 보내려고 합니다. 사용 가능한 BLX 잔액은 다음과 같습니다: ",
				errors: {
					noRecipientAddress: '수취인 지갑 주소가 필요해요',
					noSendToOwn: "자신의 지갑 주소로 보낼 수 없어요",
					amountRequired: '금액이 필요해요',
					invalidPrivateKey: '잘못된 개인 키',
					insufficientBalance: '잔액부족',
					isRequired: '필수 항목입니다',
					invalidWalletAddress: '잘못된 지갑 주소',
					insufficientGasFee: '가스에 대한 BLXQ 잔액이 부족합니다.',
					insufficientAvailableBonus: "사용 가능한 잔액이 부족합니다. 일부 토큰은 아직 잠겨 있습니다"
				}, 

				transactionSent: '거래가 블록체인으로 전송되었습니다. 잠시만 기다려주세요.',
				transactionRequestSent: 'Transaction sent. Please wait.',
				transactionCancelled: '메타마스크에서 거래를 취소하셨습니다. 다시 시도해주세요.',
				errorSending: '토큰 전송 오류',
			},

			deposit: {
				depositHoms: 'HOMS 받기',
				balance: '지갑 잔고',
				scanQr: 'QR 코드를 스캔하거나 이 HOMS 지갑 주소로 코인을 보냅니다',
				copy: '복사',
				copied: '복사됨!'
			},

			buy: {
				buyHoms: 'HOMS 받기',
				selectNetwork: '사용할 네트워크 선택',
				network: '네트워크',
				scanQr: 'QR 코드를 스캔하거나 이 HOMS 주소로 코인을 전송합니다',
				copy: '복사',
				copied: '복사됨!',
				payUsdt: 'USDT로 지불',
				payHoms: 'HOMS로 지불',
				transactionHash: '트랜잭션 해시',
				send: '보내기',

				requestSent: '요청이 성공적으로 제출되어 지금 처리 중입니다.',
				done: '알았어',

				errors: {
					isRequired: '필수 항목입니다',
					noUSDTAmount: 'USDT금액을입력해주세요',
					invalidHash: '유효하지 않은 해시',
				}
			},

			confirmLogout: {
				areYouSure: '로그아웃 하시겠습니까?',
				ok: '확인',
				cancel: '취소' 
			}
		},

		profile: {
			updateProfile: '프로필 갱신',
			firstName: '이름',
			lastName: '성',
			update: '갱신',
			updateEmail: '이메일 주소 갱신',
			updateMobile: '휴대폰 번호 갱신',
			save: '저장',
			edit: '수정',
			cancel: '취소해요',

			nameUpdated: '이름이 성공적으로 편집되었습니다',
			emailUpdated: '이메일이 성공적으로 편집되었습니다',
			mobileUpdated: '휴대폰 번호가 성공적으로 편집되었습니다'
		},

		history: {
			recentTransactions: '구매 내역',
			timestamp: '시각',
			amount: '수량',
			inboundAddress: '수신 주소',
			outboundAddress: '발신 주소',
			txnId: '거래아이디',
			status: '상태',
			input: '입금',
			output: '출금',
			recentTransfers: '자산 이동 내역',
			hash: '해시'
		},

		changePin: {
			changePin: 'PIN 변경',
			currentPin: '현재 비밀번호',
			newPin: '새 비밀번호 ',
			confirmNewPin: '새 비밀번호 확인',
			confirm: '확인',
			changedPin: 'PIN을 성공적으로 변경했습니다',

			errors: {
				noMatchingPins: '입력한 새 PIN이 일치하지 않음',
				noCurrentPin: '잘못된 전류 PIN',
				currentPinDigits: '현재 PIN은 6자리여야 해요',
				newPinDigits: '새 PIN은 6자리여야 해요',
			}
		}
	}
};


const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;