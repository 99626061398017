import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    init: {
      homsBonusSymbol: "HOMSB",
      isTest: false,
      labelSend: "",
      dashboardState: 'mainwallet',
      metamask: {},
      address: {},
      viewPrivKey: {
        state: 'init',
      },
      viewPrivKeyStep2: {
        state: 'state'
      },
      privClose: {
        state: ''
      },
      address: {},
      profile: {

      },
      fund: {
        balance: 0,
        history: [],
      },
      mintedFund: {
        balance: 0,
        history: [],
      },
      selectedAsset: {

      },
      contract: {},
      swap: false,
      blxqBalanceLoading: true,
      state: 'init',
       wallet:  {
        isConnected: false,
        networkOk: false,
        address: '',
        network: '',
        balance: 0,
        bonusUnlockedBalance: 0,
        bonusLockedBalance: 0,
        blxqBalance: "",

       }
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
