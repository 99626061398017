import Vue from 'vue'
import VueRouter from 'vue-router' 

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/components/Login')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/components/Signup')
  },
  {
    path: '/setup-profile',
    name: 'SetupProfile',
    component: () => import(/* webpackChunkName: "setup-profile" */ '@/components/SetupProfile')
  },
  {
    path: '/setup-wallet',
    name: 'SetupWallet',
    component: () => import(/* webpackChunkName: "setup-wallet" */ '@/components/SetupWallet')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "setup-profile" */ '@/components/Dashboard')
  },

  {
    path: '/change-pin',
    name: 'ChangePin',
    component: () => import('@/components/ChangePin.vue')
  },
  {
    path: '/redeem',
    name: 'Redeem',
    component: () => import('@/components/Redeem.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/components/History.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/components/Profile.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
