<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'AppComponent',
  data: function() {
    return { 
    }
  },
  methods: {
    defaultLang() {
      if(localStorage.getItem('lang') == '' || localStorage.getItem('lang') == undefined) {
        localStorage.setItem('lang', 'en')
      }
    },
  },

  beforeMount(){
    this.defaultLang();

  },

  mounted(){
  
  },
  beforeCreate() {
    if(localStorage.getItem('lang') == '') {
        localStorage.setItem('lang', 'en')
      }
  },
  created () {
    this.defaultLang();
  },
  watch: {
    
  }
}
</script>

<style>
  @font-face {
    font-family: OpenSans-SB;
    src: url(./assets/fonts/OpenSans-Semibold.ttf);
  }

  @font-face {
    font-family: OpenSans-B;
    src: url(./assets/fonts/OpenSans-Bold.ttf);
  }

  @font-face {
    font-family: OpenSans;
    src: url(./assets/fonts/OpenSans-Regular.ttf);
  }

  @font-face {
    font-family: OpenSans-L;
    src: url(./assets/fonts/OpenSans-Light.ttf);
  }
  .loader {
    width: 50px;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
  }

  .btn-main {
    background-image: linear-gradient(to bottom, #664802, #a77c1b) !important;
    background-repeat:no-repeat;
    color: #FFF !important;
    width: 100%;
  }

  .alert-danger {
    color: #a94442;
    background-color: #e1a7a6;
    border-color: #e1a7a6;
  }

  .help-block {
    font-size:1em;
    padding: 0;
    margin: 0;
    color: #a94442; 
  }

  #captchaImg {
      background-color:transparent;
      width:160px;
      height:60px;
      padding:5px;
      object-fit:contain;
  }

  #captcha {
    margin-top:10px;
    margin-bottom:20px;
    width: 75%;
    padding: 10px;
    border-radius:5px;
  }

  #captcha input {
    height:61px;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 2px white solid;
    border-radius:0px;
    font-size:30px;
    padding:5px;
    background:none;
    color:#fff;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; 
    bottom: 0.5em;
    width: 100%;
  }

  #signupBtn {
    border: 1px solid #c08000;
    color: #fff;
    width: 100%;
  }

  .modal-header, #modal-header {
    background: linear-gradient(to bottom, #231f20, #070607) !important;
    color: #c08000 !important;
    fill:#c08000 !important;
    justify-content: space-between;
  }
</style> 

