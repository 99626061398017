import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios' 
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import i18n from '@/i18n';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// only import the icons you use to reduce bundle size
import 'vue-awesome/icons/flag'

// or import all icons if you don't care about bundle size
import 'vue-awesome/icons'

/* Register component with one of 2 methods */

import Icon from 'vue-awesome/components/Icon'

// globally (in your main .js file)
Vue.component('v-icon', Icon)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)   

Vue.component(VueQrcode.name, VueQrcode);

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['blox-authz'] = localStorage.getItem("tokenId");

let lang = localStorage.getItem("lang");

if(lang == null || 
  lang == undefined ||
  lang == '') {
   i18n.locale = "en";
} else {
  i18n.locale = lang;
}

Object.defineProperty(Vue.prototype, 'i18n', { value: i18n })

new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
})

